import { all } from "./permissions";

export const menuItems = [
  
  

  
  

  

  
  
 
  {
    id: 21,
    label: "menu.menuitems.marketing.text",
    icon: "dripicons-document",
    isAdmin: true,
    auth_users: ["marketing_manager","marketing_survey_manager_no_edit"],
    subItems: [
      
      {
        label: "menu.menuitems.marketing.subItems.marketing_survey",
        link: "/dashboard/marketing_survey",
        parentId: 21,
        auth_users: ["marketing_manager","marketing_survey_manager_no_edit"],
      },
      {
        label: "menu.menuitems.marketing.subItems.marketing_districts_management",
        link: "/dashboard/marketing_districts_management",
        parentId: 21,
        auth_users: ["marketing_manager","marketing_survey_manager_no_edit"],
        can_see: all,
      },
      {
        label: "menu.menuitems.marketing.subItems.marketing_team_management",
        link: "/dashboard/marketing_team_management",
        parentId: 21,
        auth_users: ["marketing_manager","marketing_survey_manager_no_edit"],
      },

      // {
      //   label: "menu.menuitems.marketing.subItems.website_cms",
      //   link: "/dashboard/website_cms",
      //   parentId: 21,
      //   can_see: all,
      // },
    ],
  },

];
